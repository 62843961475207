import React from "react";
import { initializeAnalytics } from "./services/analytics";

import AppRoutes from "./routes/approutes";
import ScrollToTop from "./components/Common/scroll-to-top";
import CookieNotice from "./components/Cookies/cookie-notice";
import Navigation from './components/Common/navigation';
import Footer from "./components/Common/footer";

import usePageTrack from "./hooks/usePageTrack";

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/responsive.css';

initializeAnalytics();

function App() {
  usePageTrack();

  return (
    <>
      <ScrollToTop />
      <Navigation />
      <AppRoutes />
      <Footer />
      <CookieNotice />
    </>
  );
};

export default App;