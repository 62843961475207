const KEYS = {
    NOTICE: 'cap-cookie-notice',
    CHAT: 'cap-chat-id',
};

export const getUserConsent = () => {
    const userConsent = localStorage.getItem(KEYS.NOTICE);
    if (userConsent) return JSON.parse(userConsent);

    return null;
};

export const storeUserConsent = (userConsent) => {
    localStorage.setItem(KEYS.NOTICE, JSON.stringify(userConsent));
};

export const storeChatId = (chatId) => {
    localStorage.setItem(KEYS.CHAT, chatId);
};

export const getChatId = () => {
    return localStorage.getItem(KEYS.CHAT);
};