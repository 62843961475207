import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const http = {
  post: axiosInstance.post,
  put: axiosInstance.put,
  get: axiosInstance.get,
  delete: axiosInstance.delete,
  patch: axiosInstance.patch,
};

export const setAuthTokenHeader = (token) => {
  axiosInstance.defaults.headers.common['x-auth-token'] = token;
};

export default http;