import React from 'react';
import classNames from 'classnames';

import { IoClose } from "react-icons/io5";
import { AnimatePresence, motion } from 'framer-motion';

import "../../styles/popup.css"

const Popup = ({ children, isActive, onClosePopup }) => {
    return (
        <AnimatePresence>
            {children && (
                <div className={classNames({ "popup-overlay": true, "active": isActive })} onClick={onClosePopup}>
                    <motion.div
                        initial={{ transform: "translateY(100%)", opacity: 0, scale: 0 }}
                        animate={{ transform: "translateY(0)", opacity: 1, scale: 1 }}
                        exit={{ transform: "translateY(100%)", opacity: 0 }}
                        className="popup-content"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <motion.button 
                            onClick={onClosePopup}
                            className='popup-close-button-container d-flex justify-content-center align-items-center' 
                            initial={{ scale: 0 }} 
                            animate={{ scale: 1 }}
                        >
                            <IoClose className='popup-close-button-icon'/>
                        </motion.button>

                        {children}
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
};

export default Popup;
