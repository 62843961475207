import React from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import styles from './style.module.css';

const CookiePreferences = ({ preferences, onChangePreference }) => {
    return ( 
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >   
            <h5 className={styles.title}>🍪 Cookie Preferences</h5>

            <ul className={styles.preferences}>
                <li className={classNames([styles.preferencesItem, 'd-flex justify-content-start align-items-start'])}>
                    <label className={styles.checkbox}>
                        <input type="checkbox" checked={preferences.essential} onChange={() => onChangePreference('essential')} />
                        <div className={styles.checkboxContainer}>
                            <div className={styles.checkboxFilled}></div>
                        </div>
                    </label>

                    <div>
                        <p>Essential Cookies</p>
                        
                        <ul className={styles.preferencesSublist}>
                            <li className={styles.preferencesSublistItem}>Ensure the website functions properly and securely.</li>
                        </ul>
                    </div>
                </li>
                
                <li className={classNames([styles.preferencesItem, 'd-flex justify-content-start align-items-start'])}>
                    <label className={styles.checkbox}>
                        <input type="checkbox" checked={preferences.functional} onChange={() => onChangePreference('functional')} />
                        <div className={styles.checkboxContainer}>
                            <div className={styles.checkboxFilled}></div>
                        </div>
                    </label>

                    <div>
                        <p>Functional Cookies</p>
                        
                        <ul className={styles.preferencesSublist}>
                            <li className={styles.preferencesSublistItem}>Remember user settings like language and login credentials.</li>
                        </ul>
                    </div>
                </li>
               
                <li className={classNames([styles.preferencesItem, 'd-flex justify-content-start align-items-start'])}>
                    <label className={styles.checkbox}>
                        <input type="checkbox" checked={preferences.analytics} onChange={() => onChangePreference('analytics')} />
                        <div className={styles.checkboxContainer}>
                            <div className={styles.checkboxFilled}></div>
                        </div>
                    </label>

                    <div>
                        <p>Analytics Cookies</p>
                        
                        <ul className={styles.preferencesSublist}>
                            <li className={styles.preferencesSublistItem}>Track website usage to improve user experience.</li>
                        </ul>
                    </div>
                </li>
                
                <li className={classNames([styles.preferencesItem, 'd-flex justify-content-start align-items-start'])}>
                    <label className={styles.checkbox}>
                        <input type="checkbox" checked={preferences.marketing} onChange={() => onChangePreference('marketing')} />
                        <div className={styles.checkboxContainer}>
                            <div className={styles.checkboxFilled}></div>
                        </div>
                    </label>

                    <div>
                        <p>Marketing Cookies</p>
                        
                        <ul className={styles.preferencesSublist}>
                            <li className={styles.preferencesSublistItem}>Deliver personalized content and advertisements.</li>
                        </ul>
                    </div>
                </li>
                
                <li className={classNames([styles.preferencesItem, 'd-flex justify-content-start align-items-start'])}>
                    <label className={styles.checkbox}>
                        <input type="checkbox" checked={preferences.external} onChange={() => onChangePreference('external')} />
                        <div className={styles.checkboxContainer}>
                            <div className={styles.checkboxFilled}></div>
                        </div>
                    </label>

                    <div>
                        <p>Third-Party Cookies</p>
                        
                        <ul className={styles.preferencesSublist}>
                            <li className={styles.preferencesSublistItem}>Support features like embedded content and payment services.</li>
                        </ul>
                    </div>
                </li>
            </ul>
        </motion.div>
    );
};
 
export default CookiePreferences;