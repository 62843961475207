import React from 'react';

import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import styles from './style.module.css';

const CookieSettings = () => {
    return ( 
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >            
            <h5 className={styles.title}>🍪 Cookie Settings</h5>

            <p className={styles.notice}>
                By clicking “Accept Cookies”, you agree to the storing of cookies on 
                your device to enhance site navigation, analyze site usage, 
                and assist in our marketing efforts. 
                View our <Link to="/policies/cookie-policy">Cookie Policy</Link> for more information.
            </p>
        </motion.div>
    );
};
 
export default CookieSettings;