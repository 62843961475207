
import ReactGA from "react-ga4";
import { convertPagePathToTitle } from "../utilities/lib";

const mapUserConsentFromBooleanToConstant = (params) => {
    return {
        'ad_user_data': params.external ? 'granted' : 'denied',
        'functionality_storage': params.functional ? 'granted' : 'denied',
        'ad_personalization': params.marketing ? 'granted' : 'denied',
        'analytics_storage': params.analytics ? 'granted' : 'denied',
        'security_storage': params.essential ? 'granted' : 'denied',
    };
};

const initializeAnalytics = () => {
    ReactGA.initialize(process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID);
};

const denyAllConsent = () => {
    ReactGA.gtag('consent', 'default', {
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'ad_storage': 'denied',
        'analytics_storage': 'denied'
    });
};

const updateUserConsent = (userConsent) => {
    ReactGA.gtag('consent', 'update', userConsent);
};

const logPageView = (path) => {
    ReactGA.send({ 
        hitType: "pageview", 
        page: path, 
        title: convertPagePathToTitle(path)
    });
};

const logEvent = (params) => {
    ReactGA.event({
        category: params.category,
        action: params.action,
        label: params.label,
    });
};

export { denyAllConsent, mapUserConsentFromBooleanToConstant, updateUserConsent, initializeAnalytics, logPageView, logEvent };