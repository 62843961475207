import React from "react";
import { useNavigate } from "react-router-dom";
import capLogo from "../../assets/logos/cap_light.svg";
import { TbBrandLinkedin, TbBrandInstagram } from "react-icons/tb";
import "../../styles/footer.css";

import { useTranslation } from "react-i18next";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigate = (url) => {
    navigate(url);
  };

  const { t } = useTranslation();

  return (
    <footer>
      <div className="d-flex justify-content-center p-5 cap-logo">
        <img src={capLogo} alt="logo" />
      </div>
      <div className="d-flex text-white p-5 justify-content-between gap-5 footer-content">
        <div>
          <h6 className="mb-5 readex-500">{t("About Us")}</h6>
          <div className="footer-links">
            <span onClick={() => handleNavigate("/about/our-history")}>{t("Navigation.Our History")}</span>
            <span onClick={() => handleNavigate("/about/who-we-are")}>{t("Navigation.CAP Today")}</span>
            <span onClick={() => handleNavigate("/about/our-leadership")}>{t("Navigation.Leadership Team")}</span>
          </div>
        </div>
        <div className="products">
          <h6 className="mb-5 readex-500">{t("Products")}</h6>
          <div className="footer-links">
            <span onClick={() => handleNavigate("/business-segments")}>{t("Navigation.Products By Function")}</span>
            <span onClick={() => handleNavigate("/business-segments")}>{t("Navigation.Our Brands")}</span>
          </div>
        </div>
        <div>
          <h6 className="mb-5 readex-500">{t("Navigation.Resources")}</h6>
          <div className="footer-links">
            <span onClick={() => handleNavigate("/resources#colour-guides-and-consultancy")}>{t("Navigation.Colour Guides & Consultancy")}</span>
            <span onClick={() => handleNavigate("/resources#paint-calculator")}>{t("Navigation.Paint Calculator")}</span>
            <span onClick={() => handleNavigate("/resources")}>{t("Navigation.Visualizer App")}</span>
            <span onClick={() => handleNavigate("/resources/painters-academy")}>{t("Navigation.Painters' Academy")}</span>
            <span onClick={() => handleNavigate("/resources#loyalty-scheme")}>{t("Navigation.Loyalty Scheme")}</span>
            <span onClick={() => handleNavigate("/resources/frequently-asked-questions")}>{t("Navigation.FAQs")}</span>
          </div>
        </div>
        <div>
          <h6 className="mb-5 readex-500">{t("Navigation.Investors")}</h6>
          <div className="footer-links">
            <span onClick={() => handleNavigate("/investors/annual-reports")}>{t("Navigation.Annual Reports")}</span>
            <span onClick={() => handleNavigate("/investors/financial-reports")}>{t("Navigation.Financial Results")}</span>
            <span onClick={() => handleNavigate("/investors/corporate-governance")}>{t("Navigation.Corporate Governance")}</span>
            <span onClick={() => handleNavigate("/investors/corporate-announcements")}>{t("Navigation.Corporate Announcements")}</span>
            <span onClick={() => handleNavigate("/investors/policies")}>{t("Navigation.Policies")}</span>
            <span onClick={() => handleNavigate("/investors/sustainability")}>{t("Navigation.Sustainability")}</span>
          </div>
        </div>
        <div>
          <h6 className="mb-5 readex-500">{t("Contact Us")}</h6>
          <div className="footer-links">
            <span onClick={() => handleNavigate("/contact")}>{t("Navigation.General Enquiries")}</span>
            <span onClick={() => handleNavigate("/contact#contacts")}>{t("Navigation.Investors")}</span>
            <span onClick={() => handleNavigate("/contact#contacts")}>{t("Navigation.Partnerships")}</span>
            <span onClick={() => handleNavigate("/contact#contacts")}>{t("Navigation.Large-Scale Projects")}</span>
            <span onClick={() => handleNavigate("/contact#store-locator")}>{t("Navigation.Store Locator")}</span>
          </div>
        </div>
        <div className="others">
          <h6 className="mb-5 readex-500">{t("Others")}</h6>
          <div className="footer-links">
            <span onClick={() => handleNavigate("/media")}>{t("News.News & Events")}</span>
            <span onClick={() => handleNavigate("/careers")}>{t("Navigation.Careers")}</span>
            <span onClick={() => handleNavigate("/careers/vacancies")}>{t("Navigation.Vacancies")}</span>
            <span onClick={() => handleNavigate("/careers/early-careers")}>{t("Navigation.Early Careers")}</span>
          </div>
        </div>
      </div>
      <div className="extras mt-4 mb-4 d-flex flex-column gap-3 align-items-center">
        <div className="social-media-links d-flex gap-5">
          <a
            href="https://www.linkedin.com/company/chemical-and-allied-products-plc/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TbBrandLinkedin/>
          </a>
          <a
            href="https://www.instagram.com/cap_plc/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TbBrandInstagram/>
          </a>
        </div>
        <div className="extra-links text-white readex-300 d-flex gap-4">
          <span>© 2024 Chemical and Allied Products PLC.</span>
          <span className="divider">|</span>
          <span onClick={() => handleNavigate("/policies/privacy-policy")}>{t("Privacy Policy")}</span>
          <span className="divider">|</span>
          <span onClick={() => handleNavigate("/policies/cookie-policy")}>{t("Cookie Policy")}</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;